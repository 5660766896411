import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateExtend } from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import {
  createAddLikeReducer,
  createAddViewReducer,
  createCopyBoardReducer,
  createCopyTemplateReducer,
  createCreateBoardReducer,
  createCreateTemplateReducer,
  createDeleteBoardReducer,
  createDeleteTemplateReducer,
  createFetchCatalogTemplatesReducer,
  createFetchUserTemplatesReducer,
  createUpdateBoardReducer,
  createUpdateTemplateReducer,
} from "./reducers";
import sortBoards from "../../domain/utils/sortBoards";
import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";
import { Processes } from "../../domain/interface/processes";

export type TemplatesSliceState = {
  tournamentId?: number
  
  templates: TemplateExtend[]
  catalog?: TemplateExtend[]
  activeTemplateId?: number
  activeBoardId?: number
  
  isLoading: boolean
  isLoaded: boolean
  isProcessed: boolean,
  isFinished: boolean,
  process?: Processes
  
  filterByName: string
  sortByDate: 'none' | 'desc' | 'asc'
};

const initialState: TemplatesSliceState = {
  templates: [],
  
  isLoaded: false,
  isLoading: false,
  isProcessed: false,
  isFinished: false,
  
  filterByName: '',
  sortByDate: 'none',
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setActiveTemplate(state, action: PayloadAction<{ id: number | undefined }>) {
      state.activeTemplateId = action.payload.id
    },
    resetPressedState(state) {
      state.isProcessed = false
      state.isFinished = false
      state.process = undefined
    },
    setActiveBoard(state, action: PayloadAction<{ id: number | undefined }>) {
      state.activeBoardId = action.payload.id
    },
    favoriteBoard(state, action: PayloadAction<{ id: number }>) {
      if (!state.activeTemplateId) return
      const activeTemplate = state.templates.find(template => template.id === state.activeTemplateId)
      if (!activeTemplate) return
      const board = activeTemplate.boards.find(board => board.id === action.payload.id)
      if (!board) return
      board.isFavorite = !board.isFavorite
      activeTemplate.boards = sortBoards(activeTemplate.boards as Board[]) as any
    },
    sortBoardsByTemplate(state, action: PayloadAction<{ id: number }>) {
      if (!state.activeTemplateId) return
      const activeTemplate = state.templates.find(template => template.id === state.activeTemplateId)
      if (!activeTemplate) return
      activeTemplate.boards = sortBoards(activeTemplate.boards as Board[]) as any
    },
    setFilterByName: (state, action: PayloadAction<TemplatesSliceState['filterByName']>) => {
      state.filterByName = action.payload
    },
    setSortByDate: (state, action: PayloadAction<TemplatesSliceState['sortByDate']>) => {
      state.sortByDate = action.payload
    },
    setProcess(state, action: PayloadAction<TemplatesSliceState['process']>) {
      state.process = action.payload
    },
    setTournamentId(state, action: PayloadAction<{ id: number | undefined }>) {
      state.tournamentId = action.payload.id
    },
  },
  extraReducers: (builder) => {
    createFetchUserTemplatesReducer(builder);
    createCreateTemplateReducer(builder);
    createDeleteTemplateReducer(builder);
    createUpdateTemplateReducer(builder);
    createCreateBoardReducer(builder);
    createDeleteBoardReducer(builder);
    createUpdateBoardReducer(builder);
    createCopyTemplateReducer(builder);
    createCopyBoardReducer(builder);
    createFetchCatalogTemplatesReducer(builder);
    createAddLikeReducer(builder);
    createAddViewReducer(builder);
  },
});

export const {
  setActiveTemplate,
  resetPressedState,
  setActiveBoard,
  favoriteBoard,
  sortBoardsByTemplate,
  setFilterByName,
  setSortByDate,
  setProcess,
  setTournamentId,
} = templatesSlice.actions

export default templatesSlice.reducer
